<template>
  <div class="text-end mb-3">
    <a-button class="text-end mb-1" type="primary" @click="addAccount">
      <template #icon><IconSVG name="plus" /></template>เพิ่มสิทธิ์การใช้งาน
    </a-button>
  </div>
  <a-card class="w-100 h-auto" :bodyStyle="{ padding: '24px 15px' }">
    <div class="d-flex mb-3">
      <div class="search-wrapper me-2">
        <a-input-search
          placeholder="ค้นหาชื่อบัญชี"
          class="w-100"
          @search="onSearch"
          :enter-button="undefined"
        >
          <template #prefix>
            <IconSVG name="search" />
          </template>
        </a-input-search>
      </div>
    </div>
    <a-table
      :columns="columns"
      :row-key="(record) => record.id"
      :data-source="dataSource"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <template #login="{ text }">
        <IconSVG :name="text ? 'correct' : 'incorrect'" />
      </template>
      <template #date="{ text }">
        {{ format(new Date(text), "dd/MM/yyyy") }}
      </template>
      <template #action="{ text }">
        <a-tooltip>
          <template #title>แก้ไขข้อมูลสิทธิใช้งาน</template>
          <a @click="handleClickEdit(text)" class="fw-light me-3">
            <EditOutlined />
          </a>
        </a-tooltip>
        <!-- <a-divider type="vertical" />
        <a-popconfirm
          placement="leftTop"
          title="ลบระดับสิทธิใช้งาน?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="confirmDelete(index)"
        >
          <DeleteOutlined />
        </a-popconfirm> -->
        <!-- <a-dropdown :trigger="['click']">
          <a @click.prevent>
            <a-typography-text type="secondary"
              ><EllipsisOutlined class="fs-5"
            /></a-typography-text>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0">
                <a @click.prevent class="fw-light"
                  ><EditOutlined class="me-2" />แก้ไขข้อมูลสิทธิใช้งาน</a
                >
              </a-menu-item>
              <a-menu-item key="1">
                <a @click.prevent class="fw-light">
					<a-popconfirm
					placement="leftTop"
					title="Are you sure delete this task?"
					ok-text="Yes"
					cancel-text="No"
					@confirm="confirmDelete(index)"
					>
						<DeleteOutlined class="me-2" />ลบระดับสิทธิใช้งาน
					</a-popconfirm>
				</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown> -->
      </template>
    </a-table>
  </a-card>
  <AccountModal
    v-model:visible="visibleModal"
    type="kpiReport"
    :accountId="selectedAccountId"
    @close-modal="handleCloseModal"
  />
</template>
<script lang="ts">
import { defineComponent, computed, ref, Ref, VNodeChild } from "vue";
import { usePagination } from "vue-request";
import { format } from "date-fns";
import { reportService } from "@/_services";
import {
  TableState,
  TableStateFilters,
} from "ant-design-vue/es/table/interface";
import {
  /* EllipsisOutlined, */
  EditOutlined,
  // DeleteOutlined,
} from "@ant-design/icons-vue";
import IconSVG from "@/components/IconSVG.vue";
import { accountsColumns as columns } from "../_components/columns";
import AccountModal from "../_components/AccountModal.vue";
type Pagination = TableState["pagination"];

interface MenuInfo {
  key: string;
  keyPath: string[];
  item: VNodeChild;
  domEvent: MouseEvent;
}
export default defineComponent({
  components: {
    IconSVG,
    //EllipsisOutlined,
    AccountModal,
    EditOutlined,
    // DeleteOutlined,
  },
  setup() {
    const total = ref<number>(0);
    const visibleModal = ref<boolean>(false);
    const visibleMenu = ref<boolean[]>();
    const selectedAccountId = ref<number>();

    const { data: dataSource, run, loading, current, pageSize } = usePagination(
      reportService.getAccounts,
      {
        defaultParams: [{ page: 1, size: 5 }],
        pagination: { pageSizeKey: "size", currentKey: "page" },
        formatResult: (result) => {
          visibleMenu.value = Array(pageSize.value).fill(false);
          total.value = result.data?.total ?? 0;
          return result.data?.data;
        },
      }
    );
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20"],
      showTotal: (total: number, range: number[]) =>
        `${range[0]}-${range[1]} จาก ${total} รายการ`,
      buildOptionText: (size: Ref<number>) => {
        return `${size.value}`;
      },
    }));

    const handleTableChange = (
      pag: Pagination,
      filters: TableStateFilters,
      sorter: any
    ) => {
      run({
        // results: pag?.pageSize,
        // ...( pag?.pageSize && { size: pag.pageSize } ),
        size: pag?.pageSize ?? 5,
        ...(pag?.current && { page: pag.current }),
        //sortField: sorter.field,
        //sortOrder: sorter.order,
        // ...filters,
      });
    };
    const onSearch = (searchValue: string) => {
      run({
        name: searchValue,
        page: 1,
        size: pageSize.value,
      });
    };
    const addAccount = () => {
      selectedAccountId.value = undefined;
      visibleModal.value = true;
    };
    const handleMenuClick = (e: MenuInfo, idx: number) => {
      if (e.key === "0") {
        visibleMenu.value?.map((ele, index) => {
          if (index === idx) {
            ele = false;
          }
          return ele;
        });
      }
    };
    const confirmDelete = (idx: number) => {
      // message.success('Next step.');
    };

    const handleClickEdit = (accountId: string) => {
      selectedAccountId.value = +accountId;
      visibleModal.value = true;
    }

    const handleCloseModal = (fetch?: boolean) => {
      visibleModal.value = false
      selectedAccountId.value = undefined
      if (fetch) {
        run({
          page: 1,
          size: pageSize.value
        })
      }
    }

    return {
      dataSource,
      pagination,
      loading,
      columns,
      handleTableChange,
      onSearch,
      addAccount,
      visibleModal,
      format,
      confirmDelete,
      handleMenuClick,
      visibleMenu,
      selectedAccountId,
      handleClickEdit,
      handleCloseModal
    };
  },
});
</script>
<style scoped lang="scss">
.search-wrapper {
  width: 100%;
  :deep(.sat-input-suffix) {
    display: none;
  }
}
</style>
