
import { defineComponent, computed, ref, Ref, VNodeChild } from "vue";
import { usePagination } from "vue-request";
import { format } from "date-fns";
import { eLearningService } from "@/_services";
import {
  TableState,
  TableStateFilters,
} from "ant-design-vue/es/table/interface";
import {
  /* EllipsisOutlined, */
  EditOutlined,
  // DeleteOutlined,
} from "@ant-design/icons-vue";
import IconSVG from "@/components/IconSVG.vue";
import { accountsColumns as columns } from "../_components/columns";
import AccountModal from "../_components/AccountModal.vue";
type Pagination = TableState["pagination"];

interface MenuInfo {
  key: string;
  keyPath: string[];
  item: VNodeChild;
  domEvent: MouseEvent;
}
export default defineComponent({
  components: {
    IconSVG,
    //EllipsisOutlined,
    AccountModal,
    EditOutlined,
    // DeleteOutlined,
  },
  setup() {
    const total = ref<number>(0);
    const visibleModal = ref<boolean>(false);
    const visibleMenu = ref<boolean[]>();
    const selectedAccountId = ref<number>();

    const { data: dataSource, run, loading, current, pageSize } = usePagination(
      eLearningService.getAccounts,
      {
        defaultParams: [{ page: 1, size: 5 }],
        pagination: { pageSizeKey: "size", currentKey: "page" },
        formatResult: (result) => {
          visibleMenu.value = Array(pageSize.value).fill(false);
          total.value = result.data?.total ?? 0;
          return result.data?.data;
        },
      }
    );
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
      showSizeChanger: true,
      pageSizeOptions: ["5", "10", "20"],
      showTotal: (total: number, range: number[]) =>
        `${range[0]}-${range[1]} จาก ${total} รายการ`,
      buildOptionText: (size: Ref<number>) => {
        return `${size.value}`;
      },
    }));

    const handleTableChange = (
      pag: Pagination,
      filters: TableStateFilters,
      sorter: any
    ) => {
      run({
        // results: pag?.pageSize,
        // ...( pag?.pageSize && { size: pag.pageSize } ),
        size: pag?.pageSize ?? 5,
        ...(pag?.current && { page: pag.current }),
        //sortField: sorter.field,
        //sortOrder: sorter.order,
        // ...filters,
      });
    };
    const onSearch = (searchValue: string) => {
      run({
        name: searchValue,
        page: 1,
        size: pageSize.value,
      });
    };
    const addAccount = () => {
      selectedAccountId.value = undefined;
      visibleModal.value = true;
    };
    const handleMenuClick = (e: MenuInfo, idx: number) => {
      if (e.key === "0") {
        visibleMenu.value?.map((ele, index) => {
          if (index === idx) {
            ele = false;
          }
          return ele;
        });
      }
    };
    const confirmDelete = (idx: number) => {
      // message.success('Next step.');
    };

    const handleClickEdit = (accountId: string) => {
      selectedAccountId.value = +accountId;
      visibleModal.value = true;
    }

    const handleCloseModal = (fetch?: boolean) => {
      visibleModal.value = false
      selectedAccountId.value = undefined
      if (fetch) {
        run({
          page: 1,
          size: pageSize.value
        })
      }
    }

    return {
      dataSource,
      pagination,
      loading,
      columns,
      handleTableChange,
      onSearch,
      addAccount,
      visibleModal,
      format,
      confirmDelete,
      handleMenuClick,
      visibleMenu,
      selectedAccountId,
      handleClickEdit,
      handleCloseModal
    };
  },
});
